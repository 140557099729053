<template>
  <Article class="not-found">
    <h1>
      <Illustration404 class="illustration" alt="404 - Finner ikke siden" />
    </h1>
    <span class="explaination">
      er <em>utviklersk</em> for:
      <span>Jeg fant ikke siden du leter etter. </span>
    </span>
    <nav class="not-found-navigation">
      Hva med å prøve en av disse?
      <ul>
        <li class="not-found-navigation-list-item">
          <router-link to="/prosjekter/">
            Liste over prosjekter jeg har jobbet på</router-link
          >
        </li>
        <li class="not-found-navigation-list-item">
          <router-link to="/om-meg/">Min CV</router-link>
        </li>
        <li class="not-found-navigation-list-item">
          <router-link to="/kontakt/">Kontakt meg</router-link>
        </li>
      </ul>
    </nav>
  </Article>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Illustration404 from "@/assets/404.svg";

export default defineComponent({
  components: {
    Illustration404,
  },
});
</script>

<style lang="scss" scoped>
.not-found {
  text-align: center;
}

.explaination {
  font-size: 1.8rem;
  text-align: center;

  span {
    display: block;
    font-size: 3.6rem;
  }
}
.illustration {
  max-width: 40rem;
  height: auto;
  width: 100%;
  margin: 0 auto;
  padding: 4rem;
  display: block;
}

.not-found-navigation {
  margin-top: 4rem;
}

.not-found-navigation-list-item {
  margin: 1rem;
}
</style>
